.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.amplify-tabs {
    display: none;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 100px;
  height: 100px;
  border: 20px solid #f3f3f3; /* Light grey */
  border-top: 20px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: var(--w-margin);
  width: var(--w-size);
  height: var(--w-size);
  background-color: transparent;
  border: none;
  -webkit-user-select: none;
}
.spinner-text {
}
#overlay {
  background-color: rgba(255,255,255,0.7);
  display: none;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

#overlay.show {
  display: block;
}

.span-button {
  cursor: pointer;
}

.pageContent {
  margin-top: 20px;
}
